










































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Reports } from '@/store';

@Component({})
export default class Sensors extends Vue {
    @Global.State('lang') lang;
    @Global.Mutation('setDeviceClicked') setDeviceClicked;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Reports.Getter('motion_sensor_and_meta_device_map') motion_sensor_and_meta_device_map;

    mounted(){
        this.setPageTitle('Motion Sensor');
    }

    sensorClicked(device){
        this.$router.push('/');
        this.setDeviceClicked(device.id);
    }

    getModel(meta_device) {
        return meta_device && meta_device['Model'] ? meta_device['Model'] : 'N/A';
    }

    getManufacturer(meta_device) {
        return meta_device && meta_device['Manufacturer'] ? meta_device['Manufacturer'] : 'N/A';
    }

    getInterfaceData(meta_device) {
        return meta_device && meta_device['Interface'] ? meta_device['Interface'] : 'N/A';
    }
}
